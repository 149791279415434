<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="editPickupForm"
        @submit.prevent="submitPickupForm"
      >
        <v-row class="mt-4">
          <v-col
            cols="12"
            v-if="!isOrderEmpty"
          >
            <ChooseOrderPartial
              v-if="orderFetched"
              :order-data="order"
              :payment-data="payment"
              @update="addUpdatedProperty"
              hide-order
              prepopulate
            />
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
          >
            <NewCoursePartial
              v-if="orderFetched"
              :course-data="course"
              :order-data="order"
              @update="addUpdatedProperty"
              hide-phone-number
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        @click="submitPickupForm"
      >
        Edytuj zabranie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import NewCoursePartial from './Partials/NewCourse'
import ChooseOrderPartial from './Partials/ChooseOrder'
import updateFormValue from '../../mixins/updateFormValue.vue'
import { mapState, mapActions } from 'vuex'
import { Pickup } from '../../models'
import set from 'lodash/set'

export default {
  components: {
    NewCoursePartial,
    DialogFormWrapper,
    ChooseOrderPartial
  },
  mixins: [updateFormValue],
  props: {
    editingCourse: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      orderFetched: false,
      ...new Pickup(),
      updatedParams: {},
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.courses.isProcessing,
      pickingOrder: state => state.order.entity,
    }),
    isOrderEmpty () {
      return this.editingCourse.order?.id === -1
    }
  },
  mounted () {
    const { dueDate, userNotes, timeSlot } = this.editingCourse
    this.course = {
      dueDate,
      userNotes,
      fromTime: timeSlot?.[0],
      toTime: timeSlot?.[1]
    }
    this.payment = this.pickingOrder?.payment || {}
    this.$nextTick(() => {
      this.orderFetched = true
    })
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      editCourse: 'courses/editItem',
    }),
    addUpdatedProperty (data) {
      this.updateFormValue(data)
      set(this.updatedParams, data.key, data.value)
    },
    submitPickupForm () {
      if (this.$refs.editPickupForm.validate()) {
        this.editCourse({
          tableName: 'courses',
          id: this.editingCourse.id,
          params: this.updatedParams.course
        }).then(() => { this.closeDialog() })
      }
    }
  }
}
</script>
