<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="editSwapForm"
        @submit.prevent="submitSwapForm"
      >
        <v-row class="mt-4">
          <v-col class="pt-0">
            <ChooseOrderPartial
              v-if="orderFetched"
              :order-data="order"
              :payment-data="previousPayment"
              :schedule="schedule"
              :distance="straightLineDistance"
              :swap-base-order="swapBaseOrder"
              :hide-order="!hasSwapOrder"
              swap
              prepopulate
              @update="addUpdatedProperty"
            />
          </v-col>
          <v-col class="pt-0">
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <NewCoursePartial
                  v-if="orderFetched"
                  :course-data="course"
                  :order-data="order"
                  swap
                  edit-mode
                  @update="addUpdatedProperty"
                />
              </v-col>
              <v-col class="pt-0">
                <NewPaymentPartial
                  v-if="orderFetched"
                  ref="payment"
                  :client-type="clientType"
                  course-type="Wymiana"
                  :payment-data="payment"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                  @update="addUpdatedProperty"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        @click="submitSwapForm()"
      >
        Edytuj wymianę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit, getDistanceBetweenTwoPoints } from '../../utils'
import rules from '../../utils/validators'
import NewCoursePartial from './Partials/NewCourse'
import NewPaymentPartial from './Partials/NewPayment'
import ChooseOrderPartial from './Partials/ChooseOrder'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import updateFormValue from '../../mixins/updateFormValue.vue'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { Swap } from '../../models'
import set from 'lodash/set'

export default {
  components: {
    NewCoursePartial,
    NewPaymentPartial,
    DialogFormWrapper,
    ChooseOrderPartial
  },
  mixins: [updateFormValue, afterFormSubmitted],
  props: {
    editingOrder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules,
      locationDiscount: null,
      orderFetched: false,
      destinationCoordinates: null,
      ...new Swap(),
      updatedParams: {},
      clientType: null,
      hasSwapOrder: false
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      schedule: state => state.layout.dialog.data.schedule,
      dialog: state => state.layout.dialog.data,
      swapOrder: state => state.order.swapOrder,
      isProcessing: state => state.orders.isProcessing
    }),
    straightLineDistance () {
      const baseCoordinates = { baseLat: this.department.lat, baseLng: this.department.lng }
      return this.destinationCoordinates ? getDistanceBetweenTwoPoints(baseCoordinates, this.destinationCoordinates) : 0
    },
    swapBaseOrder () {
      return this.hasSwapOrder ? this.swapOrder : null
    },
    previousPayment() {
      return this.swapOrder?.payment || {}
    }
  },
  mounted () {
    const { order, course, payment } = new Swap(this.editingOrder)
    this.order = order
    this.course = course
    this.payment = payment
    const { swapOrderId, client } = this.editingOrder
    this.clientType = client.clientType
    if (swapOrderId && swapOrderId !== -1) {
      this.hasSwapOrder = true
      this.getSwapOrder(swapOrderId)
        .then(() => {
          this.orderFetched = true
        })
    } else {
      this.$nextTick(() => {
        this.orderFetched = true
      })
    }
  },
  methods: {
    ...mapActions({
      getSwapOrder: 'order/getSwapOrder',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar',
      editOrder: 'orders/editItem',
    }),
    addUpdatedProperty (data) {
      this.updateFormValue(data)
      set(this.updatedParams, data.key, data.value)
    },
    submitSwapForm () {
      if (this.$refs.editSwapForm.validate()) {
        const { order, payment, course } = this.updatedParams
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)

        const params = {
          ...order,
          leaveCourse: course,
          payment
        }
        delete params.payment.vatPercentage // set on init, not editable
        if (!Object.keys(params.payment).length) delete params.payment

        this.editOrder({
          tableName: 'orders',
          id: this.editingOrder.id,
          params
        }).then(() => {
          this.closeDialog()
        })
      } else {
        this.showSnackbar({
          type: 'error',
          message: ['Nie wszystkie pola wypełniono poprawnie']
        })
      }
    }
  }
}
</script>
