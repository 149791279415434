<!-- This components is a middleware for recognizing editing course type -->
<template>
  <div v-if="isDataFetched">
    <EditSwap
      v-if="orderType === 'Wymiana'"
      :editing-order="order"
    />
    <EditPickup
      v-else-if="orderType === 'Zabranie'"
      :editing-course="pickupCourse"
    />
    <EditOrder
      v-else
      :editing-order="order"
    />
  </div>
</template>

<script>
import EditOrder from './EditOrder.vue'
import EditSwap from './EditSwap.vue'
import EditPickup from './EditPickup.vue'
import { mapState, mapActions } from 'vuex'
import { Course } from '../../models/index'

export default {
  components: {
    EditOrder,
    EditSwap,
    EditPickup,
  },
  data: () => ({
    isDataFetched: false
  }),
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      order: state => state.order.entity,
    }),
    dialogItem() {
      const { item, data: { isCourse } } = this.dialog
      return isCourse ? new Course(item) : item
    },
    isTriggeredFromOrdersTable() {
      return !!this.dialog.item.editableState
      // editableState exists only in order object
    },
    pickupCourse() {
      if (this.isTriggeredFromOrdersTable) {
        const pickupCourse = this.order.courses.find(course => course.courseType === 'Zabranie')
        return { ...this.order, ...pickupCourse }
      }
      return this.dialogItem
    },
    orderType () {
      const { item } = this.dialog
      if (this.isTriggeredFromOrdersTable) {
        const mode = {
          swap: 'Wymiana',
          leave: 'Podstawienie',
          transport: 'Transport',
          pickup: 'Zabranie'
        }
        return mode[item.editableState]
      }
      if (item.swapCourse) return 'Wymiana'
      return item.courseType
    },
  },
  created () {
    if (this.orderType === 'Zabranie') this.setDialogSize('small')
    const { id, client, clientId } = this.dialogItem.order || this.dialogItem
    if (id !== -1) { // -1 stands for anyContainer flag
      const getOrder = this.getOrder({ id })
      const getClient = this.getClient(clientId || client?.id)
      // single client is being fetched for ChooseClient and ChooseClientAddress components
      return Promise.all([getOrder, getClient])
        .then(() => { this.isDataFetched = true })
    } else {
      this.isDataFetched = true
    }
  },
  beforeDestroy () {
    this.clearSingleOrder()
  },
  methods: {
    ...mapActions({
      getOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      getClient: 'client/getSingleClient',
      setDialogSize: 'layout/setDialogSize',
    })
  }
}
</script>
